
import { defineComponent } from "vue";

export default defineComponent({
  components: {},
  props: ["id", "title"],
  emits: ["closeModal", "rename"],
  data() {
    return {
      renameBuffer: "",
      nameLimit: false,
    };
  },
  watch: {
    // 當偵測到title改變時，代表開啟新的分類，同時要將 input 的值清空，才不會影響到 placeholder 顯示原本的分類名稱
    title: function () {
      this.renameBuffer = "";
    },
    renameBuffer: function () {
      if (this.renameBuffer.length > 20) {
        this.nameLimit = true;
      } else {
        this.nameLimit = false;
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    rename() {
      if (this.renameBuffer.length > 20) {
        return;
      } else {
        this.$emit("rename", { renameBuffer: this.renameBuffer });
      }
    },
  },
  mounted() {
    // console.log("aa");
  },
});
