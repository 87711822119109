
import { defineComponent, PropType } from "vue";
import axios from "axios";

// interface favoritePost {
//  cnName:string;
//   collect:string;
//   collect_id:number;
//   content:string;
//   createdAt:string;
//   foreignName:string;
//   id:number;
//   image:null| string;
//   user_id:number;
// }

interface favoritePost {
  id: number;
  title: string;
  name: string;
  imageUrl: null | string;
  collect: string;
  collect_id: number;
  createdAt: string;
  user_id: number;
  index: number;
  type: string;
}

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  name: "FavoritePostCard",
  props: {
    favoritePostInfo: {
      type: Object as PropType<favoritePost>,
      required: true,
    },
  },
  emits: ["removeFromFavoriteList", "goToSpecificPost"],
  data() {
    return {
      //   favoritePostInfo: [] as Array<favoritePost>,
    };
  },
  methods: {
    removeFromFavoriteList(obj: { id: number; index: number }) {
      // 通知父層進行畫面更新，進行資料移除以及對貼文的收藏狀態改變
      this.$emit("removeFromFavoriteList", obj);
      // 移除收藏 API
      axios
        .patch(
          `${serverUrl}thematic_learning/post/${obj.id}/collect`,
          {
            follow: false,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    goToSpecificPost(obj: { postId: number; type: string }) {
      let routeUrl = "" as any;
      if (obj.type === "post") {
        routeUrl = this.$router.resolve({
          path: `specific-post`,
          query: { id: obj.postId },
        });
        this.seeMore({ postId: obj.postId });
      }
      if (obj.type === "article") {
        routeUrl = this.$router.resolve({
          path: `article?id`,
          query: { id: obj.postId },
        });
      }
      window.open(routeUrl.href, "_blank");
    },
    // 按下See more 需要發送 API 用以統計點擊數
    seeMore(obj: { postId: number }) {
      axios
        .patch(
          `${serverUrl}thematic_learning/${obj.postId}/seeMore`,
          {
            id: obj.postId,
          },
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    //
  },
});
