
import { defineComponent } from "vue";
export default defineComponent({
  name: "FavoritePostCardEmpty",
  data() {
    return {
      //   favoritePostInfo: [] as Array<favoritePost>,
    };
  },
  mounted() {
    // console.log(this.favoritePostInfo);
  },
});
