import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/icons/post/favoritePost/icon_like.svg'


const _withScopeId = n => (_pushScopeId("data-v-428f388d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "post-title-name-container d-flex flex-column justify-content-between" }
const _hoisted_2 = { class: "title" }
const _hoisted_3 = { class: "name-favorite-symbol-container d-flex justify-content-between align-items-center" }
const _hoisted_4 = { class: "name" }
const _hoisted_5 = { class: "favorite-symbol d-flex align-items-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "outer-container d-flex",
    onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (
      _ctx.goToSpecificPost({
        postId: _ctx.favoritePostInfo.id,
        type: _ctx.favoritePostInfo.type,
      })
    ), ["stop"]))
  }, [
    _createElementVNode("div", {
      class: "banner",
      style: _normalizeStyle(
        _ctx.favoritePostInfo.imageUrl
          ? { backgroundImage: `url(${_ctx.favoritePostInfo.imageUrl})` }
          : {
              backgroundImage: `url(${require('@/assets/img/post/favoritePost/default.svg')})`,
            }
      )
    }, null, 4),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.favoritePostInfo.title), 1),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.favoritePostInfo.name), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("img", {
            class: "favorite-symbol-img",
            src: _imports_0,
            alt: "",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (
              _ctx.removeFromFavoriteList({
                id: _ctx.favoritePostInfo.id,
                index: _ctx.favoritePostInfo.index,
              })
            ), ["stop"]))
          })
        ])
      ])
    ])
  ]))
}