
import { defineComponent, reactive, watch } from "vue";
import { useRoute } from "vue-router";
import axios from "axios";
import { Modal } from "bootstrap";
import Template516 from "@/views/templates/Template516.vue";
import AddToFavoriteClassificationModal from "@/components/ThemeLesson/AddToFavoriteClassificationModal.vue";
import RenameCollectionCategoryModal from "@/components/ThemeLesson/RenameCollectionCategoryModal.vue";
import NewClassificationModal from "@/components/ThemeLesson/NewClassificationModal.vue";
import DeleteClassificationModal from "@/components/ThemeLesson/DeleteClassificationModal.vue";
import FavoritePostCard from "@/components/ThemeLesson/FavoritePostCard.vue";
import FavoritePostCardFull from "@/components/MyFavoritePost/FavoritePostCard.vue";
import FavoritePostCardEmpty from "@/components/ThemeLesson/FavoritePostCardEmpty.vue";
import CollectionCategory from "@/components/ThemeLesson/CollectionCategory.vue";
import CollectionCategoryEmpty from "@/components/MyFavoritePost/CollectionCategoryEmpty.vue";
import Toast from "@/components/Toast/Toast.vue";
import mixins from "@/mixins/index";
const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    Template516,
    AddToFavoriteClassificationModal,
    RenameCollectionCategoryModal,
    NewClassificationModal,
    DeleteClassificationModal,
    FavoritePostCard,
    FavoritePostCardFull,
    FavoritePostCardEmpty,
    CollectionCategory,
    CollectionCategoryEmpty,
    Toast,
  },
  mixins: [mixins],
  data() {
    return {
      toastText: "",
      spinnerStatus: true,
      spinnerStatusCount: 0,
      isRendered: false,
      renameController: false,
      renameBuffer: "",
      // showPage: "favorite-and-collection-category", //all-collection-category , all-favorite , specify-category
      showPage: "favorite-and-collection-category",
      backToPageBuffer: "theme-lesson",
      addToFavoriteClassification: null as any,
      renameCollectionCategoryModal: null as any,
      newClassification: null as any,
      deleteClassification: null as any,
      mobileFavoriteRecommendedShow: "favorite",
      showMoreOptions: false,
      // 初步顯示收藏貼文
      favoriteList: [] as any,
      // 收藏貼文詳細資料頁面
      favoritePostList: [] as any,
      showAllPost: false,
      myCollectionCategory: [] as any,
      specifyCategoryPostList: [] as any,
      recommendedCoursesList: [
        {
          id: "2",
          title: "在西班牙的寵物們 Las mascotas en España 0000",
          date: "12/30 (五)",
          time: "20:00-21:00",
          name: "邱幸宣",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqhp7GG-VHgKgmV_YY02CqjkV6PV3BKJtWa79p-CTJ&s",
          type: 3,
        },
        {
          id: "2",
          title: "德文 / 母音及Umlaut發音練習 - Das deutsche Alphabet ",
          date: "12/31 (六)",
          time: "21:00-22:00",
          name: "邱幸宣",
          picture: null,
          type: 3,
        },
        {
          id: "2",
          title: "在西班牙的寵物們 Las mascotas en España 0000",
          date: "12/30 (五)",
          time: "20:00-21:00",
          name: "邱幸宣",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQqhp7GG-VHgKgmV_YY02CqjkV6PV3BKJtWa79p-CTJ&s",
          type: 3,
        },
        {
          id: "2",
          title: "在西班牙的寵物們 Las mascotas en España 0000",
          date: "12/30 (五)",
          time: "20:00-21:00",
          name: "邱幸宣",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQTIQEVYDyDDHFuYDIbyebT_I-i8VCTSjQVUg&usqp=CAU",
          type: 3,
        },
        {
          id: "2",
          title: "在西班牙的寵物們 Las mascotas en España 0000",
          date: "12/30 (五)",
          time: "20:00-21:00",
          name: "邱幸宣",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_1nXsZUzK08hsx8IA_jMre_5mP56FR-tkEg&usqp=CAU",
          type: 3,
        },
        {
          id: "2",
          title:
            "在fsafsafsafs西班牙的寵物們 Las mascotas en España 0000dsadsafsafas",
          date: "11/30 (五)",
          time: "20:00-21:00",
          name: "邱幸",
          picture:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_1nXsZUzK08hsx8IA_jMre_5mP56FR-tkEg&usqp=CAU",
          type: 3,
        },
      ],
      myCollectionCategoryId: 0,
      specifyCategoryPostTitle: "指定分類",
      followPostInfo: {
        postId: 0,
        postIndex: 0,
        status: false,
        from: "my-favorite-post",
      },
      modalId: "MyFavoritePostModile",
    };
  },
  methods: {
    // obj.info就是要顯示的文字內容
    showToast(obj: { info: string }) {
      this.toastText = obj.info;
      (this as any).$refs.Toast.showToast();
    },
    openModalDeleteClassification() {
      this.deleteClassification.show();
    },
    closeModalDeleteClassification() {
      this.deleteClassification.hide();
    },
    openModalNewClassification() {
      (this as any).$refs.NewClassificationModal.cleanInput();
      this.newClassification.show();
    },
    closeModalNewClassification() {
      this.newClassification.hide();
    },
    openModalRenameCollectionCategory() {
      this.renameCollectionCategoryModal.show();
    },
    closeModalRenameCollectionCategory() {
      this.renameCollectionCategoryModal.hide();
    },
    goToPage(url: string) {
      this.$router.push(url);
    },
    showChange(page: string) {
      this.showPage = page;
      this.scrollToTop();
    },
    moveToCollect(obj: { id: number }) {
      // console.log(obj.id);
      this.followPostInfo.postId = obj.id;
      (this as any).$refs.AddToFavoriteClassificationModal.favoriteListGet();
      this.addToFavoriteClassification.show();
    },
    moveToCollectDone(obj: { title: string }) {
      // 如果 this.favoritePostList 有資料，代表目前是顯示全部的收藏貼文
      if (this.favoritePostList.length > 0) {
        for (let i = 0; i < this.favoritePostList.length; i++) {
          if (this.favoritePostList[i].id === this.followPostInfo.postId) {
            this.favoritePostList[i].collect = obj.title;
            let buffer = this.favoritePostList[i];
            buffer.description2 = obj.title;
            this.favoritePostList.splice(i, 1);
            this.favoritePostList.unshift(buffer);
          }
        }
      }
      // 如果 this.specifyCategoryPostList 有資料，代表目前是顯示特定分類的收藏貼文
      else if (this.specifyCategoryPostList.length > 0) {
        for (let i = 0; i < this.specifyCategoryPostList.length; i++) {
          if (
            this.specifyCategoryPostList[i].id === this.followPostInfo.postId
          ) {
            this.specifyCategoryPostList[i].collect = obj.title;
            let buffer = this.specifyCategoryPostList[i];
            buffer.description2 = obj.title;
            this.specifyCategoryPostList.splice(i, 1);
            // console.log(this.specifyCategoryPostList);
          }
        }
      }
      this.addToFavoriteClassification.hide();

      let screenWidth = window.innerWidth;
      if (screenWidth < 768 && obj.title.length > 3) {
        let buffer = obj.title.substr(0, 3) + "...";
        this.showToast({ info: `已移至「${buffer}」收藏分類` });
      } else {
        this.showToast({ info: `已移至「${obj.title}」收藏分類` });
      }
    },
    favoriteListGet(obj: { displayedNum: number }) {
      this.spinnerStatusCount = this.spinnerStatusCount + 1;
      axios
        .get(
          `${serverUrl}thematic_learning/post/collect?page=1&displayedNum=${obj.displayedNum}`,
          {
            headers: {
              Authorization: loginToken,
            },
          }
        )
        .then((res) => {
          if (res.data.data.totalPage > 0) {
            this.showAllPost = true;
          }
          this.favoriteList = [];
          for (let i = 0; i < res.data.data.data.length; i++) {
            let buffer = {
              id: "",
              title: "",
              name: "",
              imageUrl: null,
              collect: "",
              collect_id: 0,
              createdAt: "",
              user_id: 0,
              index: i,
              type: res.data.data.data[i].type,
            };
            buffer.id = res.data.data.data[i].id;
            // buffer.title = res.data.data.data[i].content;
            if (
              res.data.data.data[i].title !== null &&
              res.data.data.data[i].title !== undefined
            ) {
              buffer.title = `${res.data.data.data[i].title}${res.data.data.data[i].content}`;
            } else {
              buffer.title = res.data.data.data[i].content;
            }
            if (
              res.data.data.data[i].foreignName !== null &&
              !res.data.data.data[i].official
            ) {
              buffer.name = `${res.data.data.data[i].foreignName}`;
            }
            if (res.data.data.data[i].imageUrl !== null) {
              buffer.imageUrl = res.data.data.data[i].imageUrl.replace(/amp;/g, "&");
            }else{
              buffer.imageUrl = res.data.data.data[i].imageUrl;
            }
            buffer.collect = res.data.data.data[i].collect;
            buffer.collect_id = res.data.data.data[i].collect_id;
            buffer.createdAt = res.data.data.data[i].createdAt;
            buffer.user_id = res.data.data.data[i].user_id;
            this.favoriteList.push(buffer);
          }
          this.spinnerStatusCount = this.spinnerStatusCount - 1;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    favoritePostGet(obj: {
      page: number;
      displayedNum: number;
      collect?: number;
    }) {
      let url = "";
      if (obj.collect === undefined) {
        url = `${serverUrl}thematic_learning/post/collect?page=${obj.page}&displayedNum=${obj.displayedNum}`;
      } else {
        url = `${serverUrl}thematic_learning/post/collect?page=1&displayedNum=999999&collect=${obj.collect}`;
      }
      this.spinnerStatusCount = this.spinnerStatusCount + 1;
      axios
        .get(url, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res.data.data);
          // this.favoritePostList = res.data.data.data;
          this.favoritePostList = [];
          this.specifyCategoryPostList = [];
          for (let i = 0; i < res.data.data.data.length; i++) {
            let buffer = {
              content: "",
              description1: "Ryan / 洪振瑋",
              description2: "生活英文",
              id: 0,
              user_id: 0,
              image: null,
              collect_id: 0,
              type: res.data.data.data[i].type,
            };
            // buffer.content = res.data.data.data[i].content;
            if (
              res.data.data.data[i].title !== null &&
              res.data.data.data[i].title !== undefined
            ) {
              buffer.content = `${res.data.data.data[i].title}${res.data.data.data[i].content}`;
            } else {
              buffer.content = res.data.data.data[i].content;
            }
            if (
              res.data.data.data[i].foreignName !== null &&
              !res.data.data.data[i].official
            ) {
              buffer.description1 = `${res.data.data.data[i].foreignName}`;
            }
            buffer.description2 = res.data.data.data[i].collect;
            buffer.id = res.data.data.data[i].id;
            buffer.user_id = res.data.data.data[i].user_id;
            buffer.image = res.data.data.data[i].imageUrl;
            if (buffer.image) {
              buffer.image = res.data.data.data[i].imageUrl.replace(
                /amp;/g,
                "&"
              );
            }
            buffer.collect_id = res.data.data.data[i].collect_id;

            if (obj.collect === undefined) {
              this.favoritePostList.push(buffer);
            } else {
              this.specifyCategoryPostList.push(buffer);
            }
          }
          setTimeout(this.favoritePostMoreOptions, 300);
          this.spinnerStatusCount = this.spinnerStatusCount - 1;
          // console.log(this.favoritePostList);
          // console.log(this.specifyCategoryPostList);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    removeFavoritePost(obj: { id: number }) {
      if (this.favoritePostList.length > 0) {
        for (let i = 0; i < this.favoritePostList.length; i++) {
          if (this.favoritePostList[i].id === obj.id) {
            this.favoritePostList.splice(i, 1);
          }
        }
      }
      if (this.specifyCategoryPostList.length > 0) {
        for (let i = 0; i < this.specifyCategoryPostList.length; i++) {
          if (this.specifyCategoryPostList[i].id === obj.id) {
            this.specifyCategoryPostList.splice(i, 1);
          }
        }
      }
      this.showToast({ info: "已取消收藏" });
    },
    collectionCategoryGet() {
      this.spinnerStatusCount = this.spinnerStatusCount + 1;
      axios
        .get(`${serverUrl}thematic_learning/collect`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res.data.data)
          this.myCollectionCategory = [];
          this.myCollectionCategory = res.data.data;
          this.spinnerStatusCount = this.spinnerStatusCount - 1;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showAllFavoritePost() {
      this.showChange("all-favorite");
      this.favoritePostGet({ page: 1, displayedNum: 9999 });
    },
    newCollection(obj: { id: number; title: string }) {
      this.showToast({ info: "成功新增收藏分類" });
      this.myCollectionCategory.unshift({
        id: obj.id,
        title: obj.title,
        image: null,
      });
    },
    newCollectionError() {
      this.showToast({ info: "收藏分類名稱已經存在" });
    },
    changeCollectionCategory(obj: { title: string; id: number }) {
      this.renameController = false;
      this.renameBuffer = "";
      this.showPage = "specify-category";
      this.showSpecifyCategoryPost({ collect: obj.id });
      this.specifyCategoryPostTitle = obj.title;
      this.myCollectionCategoryId = obj.id;
    },
    showSpecifyCategoryPost(obj: { collect: number }) {
      // console.log(obj);
      this.favoritePostGet({
        page: 1,
        displayedNum: 9999999,
        collect: obj.collect,
      });
      this.scrollToTop();
    },
    deleteCollectionCategory(obj: { id: number }) {
      // 畫面上先刪除該分類
      for (let i = 0; i < this.myCollectionCategory.length; i++) {
        if (this.myCollectionCategory[i].id === obj.id) {
          this.myCollectionCategory.splice(i, 1);
        }
      }
      this.closeModalDeleteClassification();
      axios
        .delete(`${serverUrl}thematic_learning/collect/${obj.id}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          // console.log(res);
        })
        .catch((error) => {
          console.error(error);
        });
      this.showPage = "favorite-and-collection-category";
      this.showToast({ info: "已刪除收藏分類" });
      // this.favoritePostGet({ page: 1, displayedNum: 9999 });
    },
    renameCollectionCategory(obj: { renameBuffer: string }) {
      if (obj.renameBuffer !== "") {
        axios
          .post(
            `${serverUrl}thematic_learning/collect/${this.myCollectionCategoryId}`,
            {
              title: obj.renameBuffer,
            },
            {
              headers: {
                Authorization: loginToken,
              },
            }
          )
          .then((res) => {
            this.showToast({ info: `分類名稱改為「${obj.renameBuffer}」` });
            this.closeModalRenameCollectionCategory();
            for (let i = 0; i < this.myCollectionCategory.length; i++) {
              if (
                this.myCollectionCategory[i].id === this.myCollectionCategoryId
              ) {
                this.specifyCategoryPostTitle = obj.renameBuffer;
                this.myCollectionCategory[i].title = obj.renameBuffer;
                this.renameController = false;
              }
            }
            for (let j = 0; j < this.specifyCategoryPostList.length; j++) {
              this.specifyCategoryPostList[j].description2 = obj.renameBuffer;
            }
          })
          .catch((error) => {
            console.error(error);
            this.showToast({ info: `分類名稱已經存在` });
          });
      } else {
        // console.log("請輸入新的收藏分類名稱");
      }
    },
    // favoritePostMoreOptions(){
    //   const popUpMenuMobile = document.querySelectorAll(
    //     ".more-options-container-mobile"
    //   )! as NodeListOf<Element>;
    //   const moreOptionsMobile = document.querySelectorAll(
    //     ".more-options-mobile"
    //   )! as NodeListOf<Element>;

    //   for (let i = 0; i < moreOptionsMobile.length; i++) {

    //     document.addEventListener("click", (event: any) => {
    //       popUpMenuMobile[i].classList.add("hidden");
    //     });
    //     moreOptionsMobile[i].addEventListener("click", (event: any) => {
    //       event.stopPropagation();
    //       popUpMenuMobile[i].classList.toggle("hidden");
    //     });
    //   }
    // },
    favoritePostMoreOptions() {
      console;
      const popUpMenuMobile = document.querySelectorAll(
        ".more-options-container-mobile"
      )! as NodeListOf<Element>;
      const moreOptionsMobile = document.querySelectorAll(
        ".more-options-mobile"
      )! as NodeListOf<Element>;

      document.addEventListener("click", (event: any) => {
        for (let i = 0; i < popUpMenuMobile.length; i++) {
          // if (
          //   !popUpMenuMobile[i].contains(event.target) &&
          //   !moreOptionsMobile[i].contains(event.target)
          // ) {
          popUpMenuMobile[i].classList.add("hidden");
          // }
        }
      });

      for (let i = 0; i < moreOptionsMobile.length; i++) {
        moreOptionsMobile[i].addEventListener("click", (event: any) => {
          event.stopPropagation();
          popUpMenuMobile[i].classList.toggle("hidden");
        });
      }
    },
    scrollToTop(): void {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    noSelectCollectionCategoryId() {
      this.showToast({ info: `請選擇一個收藏分類` });
    },
  },
  watch: {
    spinnerStatusCount: function () {
      if (this.spinnerStatusCount > 0) {
        this.spinnerStatus = true;
      } else {
        this.spinnerStatus = false;
      }
    },
  },
  mounted() {
    // this.favoritePostGet({ page: 1, displayedNum: 9999 });
    this.favoriteListGet({ displayedNum: 3 });
    // this.favoritePostGet({page:1,displayedNum:9999});
    this.collectionCategoryGet();
    // Modal 建立
    this.addToFavoriteClassification = new Modal(
      document.getElementById(`${this.modalId}`)! as HTMLElement
    );

    this.newClassification = new Modal(
      document.getElementById("new-classification")! as HTMLElement
    );

    this.deleteClassification = new Modal(
      document.getElementById("delete-classification")! as HTMLElement
    );

    this.renameCollectionCategoryModal = new Modal(
      document.getElementById(
        "rename-collection-category-modal"
      )! as HTMLElement
    );

    document.body.addEventListener("click", () => {
      this.showMoreOptions = false;
    });
  },
  beforeUnmount() {
    this.addToFavoriteClassification = null;
    this.newClassification = null;
    this.deleteClassification = null;
    this.renameCollectionCategoryModal = null;
  },
});
